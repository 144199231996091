import If from "components/If"
import Can from "components/Can"
import useProducts from "./hooks"
import Redirect from "components/Redirect"
import PageHeader from "components/PageHeader"
import ProductLink from "./components/ProductLink"
import { Desktop, Mobile } from "components/Devices"

import { PRODUCTS } from "./constants"

import "./ProductsHome.styl"

const Products = ({ pageContext: { title } }) => {
  const { productIds } = useProducts()

  return (
    <div className="products-home">
      <Desktop>
        <Redirect to={ROUTES.home.path} />
      </Desktop>
      <Mobile>
        <PageHeader title={title} variant="medium" />
        <main className="products-home__content">
          <ul className="products-home__list">
            {productIds.map((productId, index) => (
              <Can
                I={PRODUCTS[productId]?.can.action}
                the={PRODUCTS[productId]?.can.business}
                key={index}
              >
                <li className="products-home__item">
                  <ProductLink
                    title={PRODUCTS[productId]?.title}
                    icon={PRODUCTS[productId]?.icon}
                    link={PRODUCTS[productId]?.link}
                  />
                </li>
              </Can>
            ))}
          </ul>
        </main>
      </Mobile>
    </div>
  )
}

export default Products
