import { Link } from "gatsby"
import Card from "components/Card"
import Icon from "components/Icon"
import Text from "components/Typography/Text"

import "./ProductLink.styl"

const ProductLink = ({ title, icon, link }) => (
  <Card className="product-link">
    <Link
      data-test={`product-link_${title}`}
      className="product-link__link"
      to={link}
    >
      <div className="product-link__group">
        <div className="product-link__icon">
          <Icon name={icon} />
        </div>
        <Text className="product-link__title">{title}</Text>
      </div>
      <Icon className="product-link__arrow" name="arrow-right" />
    </Link>
  </Card>
)

export default ProductLink
