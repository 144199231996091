import { useCallback, useEffect, useState } from "react"

import services from "services"
import auth from "utils/auth"

const useProducts = () => {
  const user = auth.getUser()
  const [productIds, setProductIds] = useState([])

  const sortBusiness = useCallback((listBusiness) => {
    const productIdsSorted = listBusiness.map(
      (business) => business.business_id
    )

    setProductIds(productIdsSorted)
  }, [])

  const fetchBusiness = useCallback(async () => {
    return services.user.business({ user_id: user.user_id })
  }, [user.user_id])

  useEffect(() => {
    fetchBusiness().then(sortBusiness)
  }, [fetchBusiness, sortBusiness])

  return {
    productIds,
  }
}

export default useProducts
