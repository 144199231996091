/**
 * List of ITEMS used to create ProductLinks
 *
 * This list can be different in jest environment because is mocked.
 */
export const PRODUCTS = {
  12: {
    title: "Conta Corrente",
    icon: "shop",
    link: ROUTES.checkingAccount.path,
    can: {
      business: "checkingAccount",
      action: "management",
    },
  },
  7: {
    title: "Crédito Consignado",
    icon: "negotiation",
    link: ROUTES.consignment.path,
    can: {
      business: "consignment",
      action: "management",
    },
  },
  9: {
    title: "FGTS",
    icon: "piggy-bank",
    link: ROUTES.fgts.path,
    can: {
      business: "fgts",
      action: "management",
    },
  },
  2: {
    title: "Microcrédito",
    icon: "hand-coin",
    link: ROUTES.microloans.path,
    can: {
      business: "itiMicroloans",
      action: "management",
    },
  },
  4: {
    title: "Rede",
    icon: "machine",
    link: ROUTES.rede.path,
    can: {
      business: "rede",
      action: "management",
    },
  },
  13: {
    title: "iCDC",
    icon: "phone-apps",
    link: ROUTES.icdc.path,
    can: {
      business: "icdc",
      action: "management",
    },
  },
  15: {
    title: "Consórcio",
    icon: "consortium",
    link: ROUTES.consortium.path,
    can: {
      business: "consortium",
      action: "management",
    },
  },
  16: {
    title: "Veículos",
    icon: "vehicles",
    link: ROUTES.vehicles.path,
    can: {
      business: "vehicles",
      action: "management",
    },
  },
}
